<template>
  <div class="contentBg" v-if="isShow == 2">
    <div class="finance">
      <div
          id="payrollEcharts"
          style="height:20rem;padding:1rem 0">
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {

  },
  data() {
    return {
      isShow:2,

    };
  },
  methods:{
    switchTaxBillboard(){
      let vm = this;
      vm.$emit('func',vm.isShow);
    },
    //工资表
    payrollEcharts() {
      let vm = this;
      if (document.getElementById("payrollEcharts") == null) return;
      this.$echarts.init(document.getElementById("payrollEcharts")).dispose();

      vm.$nextTick(() => {
        let myChart = vm.$echarts.init(
            document.getElementById("payrollEcharts")
        );

        // 组合参数
        const series = [
          {
            name: '出勤工资',
            type: 'line',
            stack: 'Total',
            data: [120, 132, 101, 134, 90, 230, 210],
            smooth: true
          },
          {
            name: '补贴',
            type: 'line',
            stack: 'Total',
            data: [220, 182, 191, 234, 290, 330, 310],
            smooth: true
          },
          {
            name: '奖励',
            type: 'line',
            stack: 'Total',
            data: [220, 182, 191, 234, 290, 330, 310],
            smooth: true
          },
          {
            name: '其他',
            type: 'line',
            stack: 'Total',
            data: [220, 182, 191, 234, 290, 330, 310],
            smooth: true
          },
          {
            name: '扣款',
            type: 'line',
            stack: 'Total',
            data: [220, 182, 191, 234, 290, 330, 310],
            smooth: true
          },
        ];

        const option = {
          tooltip: {
            trigger: 'axis'
          },
          legend: {
            data: ['出勤工资', '补贴' ,'奖励','其他','扣款'],
            top:'3.5%',
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月']
          },
          yAxis: {
            type: 'value'
          },
          grid: {
            left: '0',
            right: '2%',
            bottom: '2%',
            containLabel: true
          },
          series: series,
        };
        myChart.setOption(option);
        window.addEventListener("resize", function () {
          myChart.resize();
          // vm.exportChart11 = myChart;
        });
      });
    },
  },
  mounted() {
    this.payrollEcharts();
  },
}
</script>

<style scoped>
.contentBg{
  padding: 0;
}
</style>