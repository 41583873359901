<template>
  <div class="incomeStateBtn" v-if="isBtn == 11">
       <div class="hover" @click="switchBtn(11)">概览</div>
       <div @click="switchBtn(12)">明细</div>
  </div>
  <div class="incomeStateBtn" v-if="isBtn == 12">
    <div  @click="switchBtn(11)">概览</div>
    <div class="hover" @click="switchBtn(12)">明细</div>
  </div>
<!--  概览-->
  <div class="overview" v-if="isBtn == 11">
      <incomeStatementEcharts></incomeStatementEcharts>
      <div class="contentBg">
      <div class="incomePrice">
          <div class="left"><el-icon><Money /></el-icon>营业收入</div>
          <div class="right">296,783.45<el-icon><ArrowRight /></el-icon></div>
      </div>
      <div class="incomePrice">
        <div class="left"><div class="d1 drop"></div>营业成本</div>
        <div class="right">12,277.30<el-icon><ArrowRight /></el-icon></div>
      </div>
     <div class="incomePrice">
      <div class="left"><div class="d2 drop"></div>费用及其他</div>
      <div class="right">557,521.89<el-icon><ArrowRight /></el-icon></div>
     </div>
     <div class="incomePrice">
      <div class="left"><div class="d3 drop"></div>利润</div>
      <div class="right">-273,015.74<el-icon><ArrowRight /></el-icon></div>
    </div>
  </div>
  </div>
<!--  明细-->
  <div class="detail" v-if="isBtn == 12">
     <profitDetailsEcharts></profitDetailsEcharts>
  </div>
</template>
<script>
import IncomeStatementEcharts from "@/components/financialManagement/incomeStatementEcharts";
import ProfitDetailsEcharts from "@/components/financialManagement/profitDetailsEcharts";
export default {
  components: {
    ProfitDetailsEcharts,
    IncomeStatementEcharts
  },
  data() {
    return {
      isShow:1,
      isBtn:11,
    };
  },
  methods:{
    switchBtn(type){
        let vm = this;
        vm.isBtn=type;
    },
    //利润表
    incomeStatementEcharts() {
      let vm = this;
      console.log(1);
      if (document.getElementById("incomeStatementEcharts") == null) return;
      this.$echarts.init(document.getElementById("incomeStatementEcharts")).dispose();

      vm.$nextTick(() => {
        let myChart = vm.$echarts.init(
            document.getElementById("incomeStatementEcharts")
        );
        // 组合参数
        const series = [];
        const obj = {
          name: '利润表',
          type: 'pie',
          radius: ['55%', '75%'],
          avoidLabelOverlap: true,
          label: {
            formatter: '{name|{b}}\n{time|{c} %}',
            minMargin: 5,
            lineHeight: 18,
            rich: {
              time: {
                fontSize: 12,
                color: '#999'
              }
            },
            show: true
          },
          labelLine: {
            show: false
          },
          data: [
            {value: 1.00, name: '营业成本'},
            {value: 10.00, name: '费用及其他'}
          ]
        };
        series.push(obj);
        const option = {
          tooltip: {
            trigger: 'item'
          },
          series: series,
        };
        myChart.setOption(option);
        window.addEventListener("resize", function () {
          myChart.resize();
          // vm.exportChart11 = myChart;
        });
      });
    },
  },
  mounted() {
    this.incomeStatementEcharts();
  },
  created() {
  }
}
</script>

<style scoped lang="less">
.finance{
   padding: 1rem 0;
   text-align: center;
   position: relative;
  .financeText{
      position: absolute;
      top: 8rem;
      width: 100%;
     p{
      margin: 0;
     }
    .fs1{
       font-size: 0.8rem;
    }
    .fs2{
      font-size: 1.3rem;
    }
  }
}
.incomeStateBtn{
   line-height: 3.4rem;
   margin-top: 15px;
   background: #ffffff;
   display: flex;
   text-align: center;
   font-size: 1.1rem;
   margin-bottom: -15px;
   div{
     flex: 1;
   }
   .hover{
      color: #5398f5;
      border-bottom: 2px #5398f5 solid;
   }
  border-bottom: 2px #f2f2f2 solid;
}
.incomePrice{
   display: flex;
   height: 3.4rem;
   line-height: 3.4rem;
   width: 100%;
   font-size: 1.1rem;
   position: relative;
   .right{
      position: absolute;
      right: 0;
      text-align: right;
     svg{
       color: #cdcdcd;
       padding-top: 4px;
       position: absolute;
     }
   }
   .left{
       padding-left: 2.1rem;
      .el-icon{
         color: #ed751f;
         font-size: 1.3rem;
         position: absolute;
         top: 1.11rem;
         left: 0;
      }
     .drop{
        width: 0.7rem;
        height: 0.7rem;
        border-radius: 0.7rem;
        top: 1.41rem;
        position: absolute;
        left: 0.4rem;
     }
     .d1{
       background: #5470c6;
     }
     .d2{
       background: #91cc75;
     }
     .d3{
       background: #e7c146;
     }

   }
}
.incomePrice::after{
  content: "";
  background-color: #f2f2f2;
  height: 1px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}
</style>