<template>
  <div class="contentBg" v-if="isShow == 2">
    <div class="finance">
      <div
          id="payrollEchartsMonth"
          style="height:20rem;">
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {

  },
  data() {
    return {
      isShow:2,

    };
  },
  methods:{
    switchTaxBillboard(){
      let vm = this;
      vm.$emit('func',vm.isShow);
    },
    //工资表
    payrollEcharts() {
      let vm = this;
      if (document.getElementById("payrollEchartsMonth") == null) return;
      this.$echarts.init(document.getElementById("payrollEchartsMonth")).dispose();

      vm.$nextTick(() => {
        let myChart = vm.$echarts.init(
            document.getElementById("payrollEchartsMonth")
        );

        // 组合参数
        const series = [];
        const obj =  {
          name: '工资表',
          type: 'pie',
          radius: ['40%', '60%'],
          avoidLabelOverlap: false,
          center: ['50%', '59%'],
          label: {
            show: true,

          },
          emphasis: {
            label: {
              show: true,
              fontSize: 20,
              fontWeight: 'bold'
            }
          },
          labelLine: {
            show: true
          },
          data: [
            { value: 1048, name: '出勤工资' },
            { value: 735, name: '补贴' },
            { value: 580, name: '奖励' },
            { value: 484, name: '其他' },
            { value: 300, name: '扣款' }
          ]
        };
        series.push(obj);
        const option = {
          tooltip: {
            trigger: 'item'
          },
          legend: {
            top: '11%',
            left: 'center'
          },
          series: series,
        };
        myChart.setOption(option);
        window.addEventListener("resize", function () {
          myChart.resize();
          // vm.exportChart11 = myChart;
        });
      });
    },
  },
  mounted() {
    this.payrollEcharts();
  },
}
</script>

<style scoped>

</style>