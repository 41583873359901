<template>
<!-- 明细  -->
<div class="contentBg">
    <div class="finance">
      <div
          id="propertyDetailsEcharts"
          style="height:36rem;"
      >
      </div>
    </div>
</div>
</template>
<script>
export default {
  components: {

  },
  data() {
    return {
      diskData:[
        {
          "value": 40,
          "name": "资产",
          "path": "资产",
          "children": [
            {
              "value": 11176,
              "name": "主营业务收入",
              "path": "主营业务收入",
            },
            {
              "value": 11176,
              "name": "其他业务收入",
              "path": "其他业务收入",
              "children": [
                {
                  "value": 10000,
                  "name": "销售发票",
                  "path": "销售发票",
                },
                {
                  "value": 1176,
                  "name": "银行回单",
                  "path": "银行回单",
                },
              ],
            },
          ],
        },
        {
          "value": 50,
          "name": "负债",
          "path": "负债",
          "children": [
            {
              "value": 1000,
              "name": "主营业务成本",
              "path": "主营业务成本",
            },
            {
              "value": 800,
              "name": "其他业务成本",
              "path": "其他业务成本",
            },
          ]
        },
        {
          "value": 64,
          "name": "所有者权益",
          "path": "所有者权益",
          "children": [
            {
              "value": 744,
              "name": "城市维护建设税",
              "path": "城市维护建设税",
              "children": [
                {
                  "value": 744,
                  "name": "当期应交增值税",
                  "path": "当期应交增值税",
                  "children": [
                    {
                      "value": 744,
                      "name": "销项税-进项税",
                      "path": "销项税-进项税"
                    }
                  ]
                }
              ]
            },
            {
              "value": 28,
              "name": "教育费附加",
              "path": "教育费附加",
              "children": [
                {
                  "value": 744,
                  "name": "当期应交增值税",
                  "path": "当期应交增值税",
                  "children": [
                    {
                      "value": 744,
                      "name": "销项税-进项税",
                      "path": "销项税-进项税"
                    }
                  ]
                }
              ]
            },
            {
              "value": 680,
              "name": "地方教育费附加",
              "path": "地方教育费附加",
              "children": [
                {
                  "value": 744,
                  "name": "当期应交增值税",
                  "path": "当期应交增值税",
                  "children": [
                    {
                      "value": 744,
                      "name": "销项税-进项税",
                      "path": "销项税-进项税"
                    }
                  ]
                }
              ]
            },
            {
              "value": 432,
              "name": "车船税",
              "path": "车船税",
              "children": [
                {
                  "value": 432,
                  "name": "发票",
                  "path": "发票"
                }
              ]
            },
            {
              "value": 20,
              "name": "印花税",
              "path": "印花税",
              "children": [
                {
                  "value": 20,
                  "name": "购销合同",
                  "path": "购销合同"
                },
                {
                  "value": 20,
                  "name": "财产保险合同",
                  "path": "财产保险合同"
                },
                {
                  "value": 20,
                  "name": "建设工程合同",
                  "path": "建设工程合同"
                },
                {
                  "value": 20,
                  "name": "技术合同",
                  "path": "技术合同"
                },
              ]
            }
          ]
        },
      ],
      isSwitch:2
    };
  },
  methods:{

    //资产负债表明细矩形图
    propertyDetailsEcharts() {
      let vm = this;
      if (document.getElementById("propertyDetailsEcharts") == null) return;
      this.$echarts.init(document.getElementById("propertyDetailsEcharts")).dispose();

      vm.$nextTick(() => {
        let myChart = vm.$echarts.init(
            document.getElementById("propertyDetailsEcharts")
        );
        const formatUtil = vm.$echarts.format;
        const getLevelOption = [
            {
              itemStyle: {
                borderColor: '#777',
                borderWidth: 0,
                gapWidth: 1
              },
              upperLabel: {
                show: false
              }
            },
            {
              itemStyle: {
                borderColor: '#555',
                borderWidth: 5,
                gapWidth: 1
              },
              emphasis: {
                itemStyle: {
                  borderColor: '#ddd'
                }
              }
            },
            {
              colorSaturation: [0.35, 0.5],
              itemStyle: {
                borderWidth: 5,
                gapWidth: 1,
                borderColorSaturation: 0.6
              }
            }
          ];

        // 组合参数
        const series = [];
        const obj =  {
          name: '资产负债表明细',
          type: 'treemap',
          visibleMin: 300,
          label: {
            show: true,
            formatter: '{b}'
          },
          upperLabel: {
            show: true,
            height: 30
          },
          itemStyle: {
            borderColor: '#fff'
          },
          levels: getLevelOption ,
          data: vm.diskData
        };
        series.push(obj);
        const option = {
          title: {
            text: '资产负债表明细',
            left: 'center'
          },

          tooltip: {
            backgroundColor: "rgba(50,50,50,0.7)",
            borderColor: "rgba(50,50,50,0.7)",
            textStyle: {
              color: "#fff",
            },
            formatter: function (info) {
              var value = info.value;
              var treePathInfo = info.treePathInfo;

              var treePath = [];
              for (var i = 1; i < treePathInfo.length; i++) {
                let name = treePathInfo[i].name;
                if (name) {
                  treePath.push(name);
                }
              }
              if (treePath.length == 0) {
                return;
              }
              return [
                "名称：" +
                formatUtil.encodeHTML(treePath.join("/")) +
                "&nbsp;&nbsp;&nbsp;&nbsp;销售金额(万元)：" +
                formatUtil.addCommas(Math.round(value / 10000)) +
                "万元&nbsp;&nbsp;&nbsp;&nbsp;占比：" +
                formatUtil.addCommas(info.data.path),
              ].join("");
            },
          },
          series: series,
        };
        myChart.setOption(option);
        window.addEventListener("resize", function () {
          myChart.resize();
          // vm.exportChart11 = myChart;
        });
      });
    },
  },
  mounted() {
    this.propertyDetailsEcharts();
  },
  created() {
  }
}
</script>

<style scoped lang="less">
.finance{
   padding: 1rem 0;
   text-align: center;
   position: relative;
  .financeText{
      position: absolute;
      top: 7.5rem;
      width: 100%;
     p{
      margin: 0;
     }
    .fs1{
       font-size: 0.8rem;
    }
    .fs2{
      font-size: 1.3rem;
    }
  }
}
.incomeStateBtn{
   line-height: 3.4rem;
   margin-top: 15px;
   background: #ffffff;
   display: flex;
   text-align: center;
   font-size: 1.1rem;
   margin-bottom: -15px;
   div{
     flex: 1;
   }
   .hover{
      color: #5398f5;
      border-bottom: 2px #5398f5 solid;
   }
  border-bottom: 2px #f2f2f2 solid;
}
.incomePrice{
   display: flex;
   height: 3.4rem;
   line-height: 3.4rem;
   width: 100%;
   font-size: 1.1rem;
   position: relative;
   .right{
      position: absolute;
      right: 0;
      text-align: right;
     svg{
       color: #cdcdcd;
       padding-top: 4px;
       position: absolute;
     }
   }
   .left{
       padding-left: 2.1rem;
      .el-icon{
         color: #ed751f;
         font-size: 1.3rem;
         position: absolute;
         top: 1.11rem;
         left: 0;
      }
     .drop{
        width: 0.7rem;
        height: 0.7rem;
        border-radius: 0.7rem;
        top: 1.41rem;
        position: absolute;
        left: 0.4rem;
     }
     .d1{
       background: #5470c6;
     }
     .d2{
       background: #91cc75;
     }
     .d3{
       background: #e7c146;
     }

   }
}
.incomePrice::after{
  content: "";
  background-color: #f2f2f2;
  height: 1px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}

</style>