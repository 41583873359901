<template>
  <div class="contentBg"  v-if="isShow == 4">
    <div class="finance">
      <div
          id="balanceSheetEchartsYear"
          style="height:18rem;padding: 1rem 0">
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {

  },
  data() {
    return {
      isShow:4,

    };
  },
  methods:{
    switchTaxBillboard(){
      let vm = this;
      vm.$emit('func',vm.isShow);
    },
    //资产负债表
    balanceSheetEcharts() {
      let vm = this;
      if (document.getElementById("balanceSheetEchartsYear") == null) return;
      this.$echarts.init(document.getElementById("balanceSheetEchartsYear")).dispose();

      vm.$nextTick(() => {
        let myChart = vm.$echarts.init(
            document.getElementById("balanceSheetEchartsYear")
        );
        // 组合参数
        const series = [
          {
            name: '资产总计',
            data: [820, 932, 901, 934, 1290, 1330, 1320],
            type: 'line',
            smooth: true
          },
          {
            name: '负债总计',
            data: [320, 132, 701, 934, 1290, 1030, 1520],
            type: 'line',
            smooth: true
          },
          {
            name: '所有者权益',
            data: [300, 102, 601, 834, 1090, 1130, 1420],
            type: 'line',
            smooth: true
          }
        ];

        const option = {
          xAxis: {
            type: 'category',
            data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月']
          },
          yAxis: {
            type: 'value'
          },
          tooltip: {
            trigger: 'axis'
          },
          grid: {
            left: '0',
            right: '2%',
            bottom: '2%',
            containLabel: true
          },
          legend: {
            data: ['资产总计', '负债总计','所有者权益'],
            top:'6%',
          },
          series: series,
        };
        myChart.setOption(option);
        window.addEventListener("resize", function () {
          myChart.resize();
          // vm.exportChart11 = myChart;
        });
      });
    },
  },
  mounted() {
    this.balanceSheetEcharts();
  },
}
</script>

<style scoped>
.contentBg{
  padding: 0;
}
</style>