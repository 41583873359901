<template>
  <div class="contain" v-if="isShow == 1" >
    <div class="nav">
      <div class="nav_title">首页</div>
    </div>
    <div>
      <taxBillboard @func="getisShowNum">财税看板</taxBillboard>
      <paySheet @func="getisShowNum">工资表</paySheet>
      <incomeStatement @func="getisShowNum">利润表</incomeStatement>
      <balanceSheet @func="getisShowNum">资产负债表</balanceSheet>
    </div>
  </div>
  <div class="contain" v-if="isShow == 0">
    <div class="nav priceBg">
      <el-icon @click="backIndex"><ArrowLeft /></el-icon>
      <div class="nav_title">财税面板</div>
    </div>
    <taxBillboardDetail></taxBillboardDetail>
  </div>
  <div class="contain" v-if="isShow == 2">
    <div class="nav">
      <el-icon @click="backIndex"><ArrowLeft /></el-icon>
      <div class="nav_title">工资表</div>
    </div>
    <paySheetList @func="getisShowNum"></paySheetList>
  </div>
  <div class="contain" v-if="isShow == 3">
    <div class="nav">
      <el-icon @click="backIndex"><ArrowLeft /></el-icon>
      <div class="nav_title">利润表</div>
    </div>
    <incomeStatementDetail></incomeStatementDetail>
  </div>
  <div class="contain" v-if="isShow == 4">
    <div class="nav">
      <el-icon @click="backIndex"><ArrowLeft /></el-icon>
      <div class="nav_title">资产负债表</div>
    </div>
    <balanceSheetDetail></balanceSheetDetail>
  </div>
  <div class="contain" v-if="isShow == 5">
    <div class="nav priceDetailBg">
      <el-icon @click="backIndex1"><ArrowLeft /></el-icon>
      <div class="nav_title">工资表详情</div>
    </div>
    <paySheetDetail></paySheetDetail>
  </div>
</template>

<script>
import taxBillboard from "./components/financialManagement/taxBillboard.vue";
import taxBillboardDetail from "./components/financialManagement/taxBillboardDetail.vue";
import paySheet from "./components/financialManagement/paySheet.vue";
import incomeStatement from "./components/financialManagement/incomeStatement.vue";
import balanceSheet from "./components/financialManagement/balanceSheet.vue";
import paySheetList from "./components/financialManagement/paySheetList.vue";
import paySheetDetail from "./components/financialManagement/paySheetDetail.vue";
import incomeStatementDetail from "./components/financialManagement/incomeStatementDetail.vue";
import balanceSheetDetail from "./components/financialManagement/balanceSheetDetail.vue";
export default {
  components: {
    taxBillboard,
    paySheet,
    incomeStatement,
    balanceSheet,
    taxBillboardDetail,
    paySheetDetail,
    incomeStatementDetail,
    balanceSheetDetail,
    paySheetList
  },
  data() {
    return {
      showcom:0,
      isShow: 1,
    };
  },
  methods:{
    getisShowNum(data) {
      let vm = this;
      vm.isShow = data;
      console.log(data)
    },
    backIndex(){
      let vm = this;
      vm.isShow = 1;
    },
    backIndex1(){
      let vm = this;
      vm.isShow = 2;
    }
  },

}
</script>

<style lang="less">

.nav{
  height: 3.4rem;
  line-height: 3.5rem;
  text-align: center;
  position: relative;
  background: #fff;
  .el-icon{
    width: 4.2rem;
    height: 3.4rem;
    position: absolute;
    left: 0;
    color: #000;
    svg{
      font-size: 1.4rem;
    }
  }
  .nav_title{
    font-size: 1.1rem;
    color: #000000;
  }
}
.priceBg{
  background: linear-gradient(to right, #12d1ff, #02afff);
  .el-icon,.nav_title{
    color: #fff;
  }
}
.priceDetailBg{
  background: linear-gradient(to right, #feb690, #f48372);
  .el-icon,.nav_title{
    color: #fff;
  }
}
.contentBg{
  background: #fff;
  padding: 0 16px;
  margin-top: 15px;
  display: flow-root;
  .titleDiv{
    padding: 5px 0;
    position: relative;
    h2{
      font-size: 1.24rem;
      border-left: 3px #5398f5 solid;
      padding-left: 11px;
      line-height: 18px;
      font-weight: normal;
      div{
        width: 5rem;
        text-align: right;
        position: absolute;
        right: 0;
        top: 0;
        padding: 0;
      }
      .el-icon{
        height: 3.8rem;
        color: #c9c9c9;
      }
    }
  }

}
</style>
