<template>
  <div class="incomeStateBtn" v-if="isBtn == 11">
    <div class="hover" @click="switchBtn(11)">概览</div>
    <div @click="switchBtn(12)">明细</div>
  </div>
  <div class="incomeStateBtn" v-if="isBtn == 12">
    <div  @click="switchBtn(11)">概览</div>
    <div class="hover" @click="switchBtn(12)">明细</div>
  </div>
  <!--  概览-->
  <div class="overview" v-if="isBtn == 11">
    <div class="contentBg">
      <div class="incomePrice incomePrice1">
        <div class="left">资产合计</div>
        <div class="right">-276,362.21</div>
      </div>
      <div class="incomePrice">
        <div class="left color">流动资产合计</div>
        <div class="right">-277,362.21</div>
      </div>
      <div class="incomePrice">
        <div class="left color">非流动资产合计</div>
        <div class="right">10,000.00</div>
      </div>
    </div>
    <div class="contentBg">
      <div class="incomePrice incomePrice1 mt16">
        <div class="left">负债合计</div>
        <div class="right">10,653.53</div>
      </div>
      <div class="incomePrice">
        <div class="left color">流动负债合计</div>
        <div class="right">10,653.53</div>
      </div>
      <div class="incomePrice">
        <div class="left color">非流动负债合计</div>
        <div class="right">0.00</div>
      </div>
    </div>
     <div class="contentBg">
      <div class="incomePrice fd">
        <div class="left">所有者权益（或股东权益）合计</div>
        <div class="right">-278,015.74</div>
      </div>
    </div>
  </div>
  <!--  明细-->
  <div class="detail" v-if="isBtn == 12">
     <propertyDetailsEcharts></propertyDetailsEcharts>
  </div>
</template>
<script>
import PropertyDetailsEcharts from "@/components/financialManagement/propertyDetailsEcharts";
export default {
  components: {
    PropertyDetailsEcharts
  },
  data() {
    return {
      isShow:1,
      isBtn:11,
    };
  },
  methods:{
    switchBtn(type){
      let vm = this;
      vm.isBtn=type;
    },

  },
  mounted() {

  },
}
</script>

<style scoped lang="less">
.incomePrice{
  display: flex;
  height: 2.4rem;
  line-height: 2.4rem;
  width: 100%;
  font-size: 0.9rem;
  position: relative;
  .right{
    position: absolute;
    right: 0;
    text-align: right;
    svg{
      color: #cdcdcd;
      padding-top: 4px;
      position: absolute;
    }
  }
  .left{
    padding-left: 0;
  }
}
.incomePrice1,.fd{
  font-weight: bold;
  font-size: 1.1rem;
  height: 3.4rem;
  line-height: 3.4rem;
}
.color{
  color: #6b6b6b;
}
.incomePrice1::after{
  content: "";
  background-color: #f2f2f2;
  height: 1px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}
.incomeStateBtn{
  line-height: 3.4rem;
  margin-top: 15px;
  background: #ffffff;
  display: flex;
  text-align: center;
  font-size: 1.1rem;
  margin-bottom: -15px;
div{
  flex: 1;
}
.hover{
  color: #5398f5;
  border-bottom: 2px #5398f5 solid;
}
border-bottom: 2px #f2f2f2 solid;
}

</style>