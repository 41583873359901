<template>
  <div class="priceDetailBg" v-if="isShow==5">
<!--    <el-select v-model="value" class="m-2" placeholder="2023-06" size="large">-->
<!--      <el-option-->
<!--          v-for="item in options"-->
<!--          :key="item.value"-->
<!--          :label="item.label"-->
<!--          :value="item.value"-->
<!--      />-->
<!--    </el-select>-->
      <div class="priceDetaiPieChart">
         <p class="font2">实发工资</p>
         <p class="font1">3,3260.22</p>
      </div>
  </div>
  <div class="wagesBg">
        <div class="contentBg">
          <div class="finance">
            <div class="titleDiv">
              <h2>出勤工资</h2>
            </div>
            <div
                id="attendanceSalary"
                style="height:13rem;">
            </div>
          </div>
        </div>
        <div class="contentBg">
          <div class="finance">
            <div class="titleDiv">
              <h2>补贴</h2>
            </div>
            <div
                id="subsidy"
                style="height:21rem;">
            </div>
          </div>
        </div>
        <div class="contentBg">
          <div class="finance">
            <div class="titleDiv">
              <h2>奖励</h2>
            </div>
            <div
                id="rewardEcharts"
                style="height:16rem;">
            </div>
          </div>
       </div>
      <div class="contentBg">
        <div class="finance">
          <div class="titleDiv">
            <h2>其他</h2>
          </div>
          <div
              id="otherEcharts"
              style="height:12rem;">
          </div>
        </div>
     </div>
      <div class="contentBg">
        <div class="finance">
          <div class="titleDiv">
            <h2>扣款</h2>
          </div>
          <div
              id="deductionEcharts"
              style="height:14rem;">
          </div>
        </div>
      </div>
  </div>
</template>
<script>
export default {
  components: {

  },
  data() {
    return {
      isShow:5,
      value:[],
    };
  },
  methods:{
    //出勤工资
    attendanceSalaryChart(){
      let vm = this;
      if (document.getElementById("attendanceSalary") == null) return;
      this.$echarts.init(document.getElementById("attendanceSalary")).dispose();

      vm.$nextTick(() => {
        let myChart = vm.$echarts.init(
            document.getElementById("attendanceSalary")
        );
        // 组合参数
        const series =  [
          {
            name: '出勤工资',
            type: 'pie',
            radius: ['50%', '70%'],
            center: ['50%', '38%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2
            },
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 18,
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: [
              { value: 1048, name: '基本工资' },
              { value: 735, name: '岗位工资' },
              { value: 580, name: '绩效工资' },
            ]
          }
        ];
        const option = {
          tooltip: {
            trigger: 'item'
          },
          legend: {
            bottom: '5%',
            left: 'center'
          },
          series: series,
        };
        myChart.setOption(option);
        window.addEventListener("resize", function () {
          myChart.resize();
          // vm.exportChart11 = myChart;
        });
      });
    },
    //补贴
    subsidyChart(){
      let vm = this;
      if (document.getElementById("subsidy") == null) return;
      this.$echarts.init(document.getElementById("subsidy")).dispose();

      vm.$nextTick(() => {
        let myChart = vm.$echarts.init(
            document.getElementById("subsidy")
        );
        // 组合参数
        const series = [
          {
            name: '补贴',
            type: 'pie',
            radius: [40, 100],
            center: ['50%', '55%'],
            roseType: 'area',
            itemStyle: {
              borderRadius: 8
            },
            data: [
              { value: 40, name: '午餐' },
              { value: 38, name: '晚餐' },
              { value: 32, name: '岗位' },
              { value: 30, name: '交通' },
              { value: 28, name: '通讯' },
              { value: 26, name: '住房' },
              { value: 22, name: '社保' },
              { value: 18, name: '其他' }
            ]
          }
        ];
        const option = {
          tooltip: {
            trigger: 'item'
          },
          legend: {
            top: '1%',
          },

          series: series,
        };
        myChart.setOption(option);
        window.addEventListener("resize", function () {
          myChart.resize();
          // vm.exportChart11 = myChart;
        });
      });
    },
    //奖励
    rewardEcharts(){
      let vm = this;
      if (document.getElementById("rewardEcharts") == null) return;
      this.$echarts.init(document.getElementById("rewardEcharts")).dispose();

      vm.$nextTick(() => {
        let myChart = vm.$echarts.init(
            document.getElementById("rewardEcharts")
        );
        // 组合参数
        const series = [
          {
            name: '奖励',
            type: 'pie',
            radius: '50%',
            center: ['50%', '56%'],
            data: [
              { value: 1048, name: '全勤奖' },
              { value: 735, name: '表彰奖' },
              { value: 580, name: '月度奖' },
              { value: 484, name: '季度奖' },
              { value: 300, name: '年度奖' }
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ];
        const option = {
          tooltip: {
            trigger: 'item'
          },
          legend: {
            left: 'left'
          },
          series: series,
        };
        myChart.setOption(option);
        window.addEventListener("resize", function () {
          myChart.resize();
          // vm.exportChart11 = myChart;
        });
      });
    },
    //其他
    otherEcharts(){
      let vm = this;
      if (document.getElementById("otherEcharts") == null) return;
      this.$echarts.init(document.getElementById("otherEcharts")).dispose();

      vm.$nextTick(() => {
        let myChart = vm.$echarts.init(
            document.getElementById("otherEcharts")
        );
        // 组合参数
        const series = [
          {
            name: '其他',
            type: 'pie',
            radius: ['50%', '80%'],
            center: ['50%', '55%'],
            // adjust the start angle
            startAngle: 180,
            label: {
              show: true,
              // formatter(param) {
              //   // correct the percentage
              //   return param.name + ' (' + param.percent * 2 + ')';
              // }
            },
            data: [
              { value: 1048, name: '其他补发' },
              { value: 735, name: '所得税' },
              {
                // make an record to fill the bottom 50%
                value: 1048 + 735,
                itemStyle: {
                  // stop the chart from rendering this piece
                  color: 'none',
                  decal: {
                    symbol: 'none'
                  }
                },
                label: {
                  show: false
                }
              }
            ]
          }
        ];
        const option = {
          legend: {
            bottom: '13%',
            left: 'center',
            // doesn't perfectly work with our tricks, disable it
            selectedMode: false
          },
          tooltip: {
            trigger: 'item'
          },
          series: series,
        };
        myChart.setOption(option);
        window.addEventListener("resize", function () {
          myChart.resize();
          // vm.exportChart11 = myChart;
        });
      });
    },
    //扣款
    deductionEcharts(){
      let vm = this;
      if (document.getElementById("deductionEcharts") == null) return;
      this.$echarts.init(document.getElementById("deductionEcharts")).dispose();

      vm.$nextTick(() => {
        let myChart = vm.$echarts.init(
            document.getElementById("deductionEcharts")
        );
        // 组合参数
        const series = [
          {
            name: '扣款',
            type: 'pie',
            radius: ['45%', '75%'],
            center: ['50%', '40%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2
            },
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 20,
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: [
              { value: 1048, name: '考勤' },
              { value: 735, name: '罚款' },
              { value: 580, name: '社保' },
              { value: 484, name: '公积金' },
              { value: 300, name: '其他' }
            ]
          }
        ];
        const option = {
          tooltip: {
            trigger: 'item'
          },
          legend: {
            bottom: '5%',
            left: 'center'
          },
          series: series,
        };
        myChart.setOption(option);
        window.addEventListener("resize", function () {
          myChart.resize();
          // vm.exportChart11 = myChart;
        });
      });
    },
  },
  mounted() {
      let vm = this;
      vm.attendanceSalaryChart();
      vm.subsidyChart();
      vm.rewardEcharts();
      vm.otherEcharts();
      vm.deductionEcharts();
  },
  created() {


  }
}
</script>

<style scoped lang="less">
.priceDetailBg{
  background: linear-gradient(to right, #feb690, #f48372);
  margin-top: -1px;
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.priceDetaiPieChart{
   border: 9px #fff solid;
   width: 14rem;
   height: 14rem;
   border-radius: 14rem;
   margin: 0 auto;
  p{
    color: #fff;
    text-align: center;
    margin: 0;
  }
  .font2{
    padding-top: 4.5rem;
  }
  .font1{
    font-size: 2rem;
    padding-top: 0.5rem;
  }
}
.wagesBg{
  padding:0 14px;
  .contentBg{
    border-radius: 5px;
  }
}
</style>