<template>
  <div class="contentBg" v-if="isShow == 2">
    <div class="finance">
      <div class="titleDiv">
        <h2>工资表
          <div @click="switchTaxBillboard()">
            <el-icon><ArrowRight /></el-icon>
          </div>
        </h2>
        <button v-if="isSwitch == 3" class="yearBtn" @click="switchCharts(4)">当月</button>
        <button v-if="isSwitch == 4" class="yearBtn" @click="switchCharts(3)">当年</button>
      </div>
      <paySheetYear v-if="isSwitch==3"></paySheetYear>
      <paySheetMonth v-if="isSwitch==4"></paySheetMonth>
    </div>
  </div>
</template>
<script>
import PaySheetYear from "@/components/financialManagement/paySheetYear";
import PaySheetMonth from "@/components/financialManagement/paySheetMonth";
export default {
  components: {
    PaySheetMonth,
    PaySheetYear

  },
  data() {
    return {
      isShow:2,
      isSwitch:3,
    };
  },
  methods:{
    switchTaxBillboard(){
      let vm = this;
      vm.$emit('func',vm.isShow);
    },
    switchCharts(type){
      let vm = this;
      vm.isSwitch = type;
    },

  },
  mounted() {

  },
}
</script>

<style scoped lang="less">
.yearBtn{
  color: #02afff;
  width: 5rem;
  height:2.4rem;
  line-height: 2.4rem;
  text-align: center;
  border: #02afff solid 1px;
  border-radius: 0.6rem;
  position: absolute;
  background: none;
  left: 0;
  font-weight: bold;
  z-index: 9999;
}
</style>