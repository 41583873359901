<template>
  <div class="contentBg"  v-if="isShow == 3">
    <div class="finance">
      <div class="titleDiv">
        <h2>利润表
          <div @click="switchTaxBillboard(3)">
            <el-icon><ArrowRight /></el-icon>
          </div>
        </h2>
        <button v-if="isSwitch == 2" class="yearBtn" @click="switchCharts(3)">当月</button>
        <button v-if="isSwitch == 3" class="yearBtn" @click="switchCharts(2)">当年</button>
      </div>
      <incomeStatementEchartsYear v-if="isSwitch==2"></incomeStatementEchartsYear>
      <incomeStatementEcharts v-if="isSwitch==3"></incomeStatementEcharts>
    </div>
  </div>
</template>
<script>
import IncomeStatementEcharts from "@/components/financialManagement/incomeStatementEcharts";
import IncomeStatementEchartsYear from "@/components/financialManagement/incomeStatementEchartsYear";
export default {
  components: {
    IncomeStatementEchartsYear,
    IncomeStatementEcharts
  },
  data() {
    return {
      isShow:3,
      isSwitch:2
    };
  },
  methods:{
    switchTaxBillboard(){
      let vm = this;
      vm.$emit('func',vm.isShow);
    },
    switchCharts(type){
      let vm = this;
      vm.isSwitch = type;
    },
    //利润表
    incomeStatementEcharts() {
      let vm = this;
      if (document.getElementById("incomeStatementEcharts") == null) return;
      this.$echarts.init(document.getElementById("incomeStatementEcharts")).dispose();

      vm.$nextTick(() => {
        let myChart = vm.$echarts.init(
            document.getElementById("incomeStatementEcharts")
        );
        // 组合参数
        const series = [];
        const obj = {
          name: '利润表',
          type: 'pie',
          radius: ['55%', '75%'],
          avoidLabelOverlap: true,
          label: {
            formatter: '{name|{b}}\n{time|{c} %}',
            minMargin: 5,
            lineHeight: 18,
            rich: {
              time: {
                fontSize: 12,
                color: '#999'
              }
            },
            show: true
          },
          labelLine: {
            show: false
          },
          data: [
            {value: 1.00, name: '营业成本'},
            {value: 10.00, name: '费用及其他'}
          ]
        };
        series.push(obj);
        const option = {
          tooltip: {
            trigger: 'item'
          },
          series: series,
        };
        myChart.setOption(option);
        window.addEventListener("resize", function () {
          myChart.resize();
          // vm.exportChart11 = myChart;
        });
      });
    },
  },
  mounted() {
    this.incomeStatementEcharts();
  },
}
</script>

<style scoped lang="less">
.finance{
  padding: 1rem 0;
  position: relative;
.financeText{
  position: absolute;
  top: 11.5rem;
  width: 100%;
  text-align: center;
    p{
      margin: 0;
    }
    .fs1{
      font-size: 0.8rem;
    }
    .fs2{
      font-size: 1.3rem;
    }
  }
}
.yearBtn{
  color: #02afff;
  width: 5rem;
  height:2.4rem;
  line-height: 2.4rem;
  text-align: center;
  border: #02afff solid 1px;
  border-radius: 0.6rem;
  position: absolute;
  background: none;
  left: 0;
  font-weight: bold;
  z-index: 9999;
}
</style>