<template>
  <div>
    <div class="contentBg">
      <el-select v-model="value" popper-class="dateClass" class="m-2" placeholder="Select"
       style="min-width: 5.5rem; max-width: 7rem;"
      >
        <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
        />
      </el-select>

    </div>
    <div class="">
     <div class="priceDateList">
         <div class="yearAndmonth">2023年6月</div>
         <div class="prcieRight">5000</div>
     </div>
     <div class="contentBg" style="display: flex;">
          <div class="priceInfoLeft">
              <p class="font1">2023年6月份工资</p>
              <p class="font2">2023-06-20 16:34:46</p>
          </div>
          <div  class="priceInfoRight" @click="switchTaxBillboard()">
               <span class="font1">5000</span><el-icon><ArrowRight /></el-icon>
          </div>
     </div>
  </div>
  </div>

</template>

<script>



export default {
  components: {

  },
  data() {
    return {
      isShow:5,
      options:[ ],
      value:new Date(new Date()).getFullYear()+'年',
    };
  },
  methods:{
    switchTaxBillboard(){
      let vm = this;
      vm.$emit('func',vm.isShow);
    },
    initYear(){
      let vm = this;
      let myDate = new  Date;
      let year = myDate.getFullYear();
      vm.initSelectYear(year);
    },
    initSelectYear(year){
      let vm = this;
      vm.options = [];
      for(let i = 0;i<6;i++){
        vm.options.push(
            {
              value:(year-i)+'年',
              label:(year-i)+'年'
            }
        );
      }
    },
    // getSelectYear(val){
    //   let vm = this;
    //   vm.selectYear=val;
    // },
    backIndex(){
      let vm = this;
      vm.$emit('func',vm.isShow);
    },

  },
  mounted() {
    let vm = this;
    vm.initYear();
    vm.yourMethod();
  },
  created() {


  }
}
</script>

<style  lang="less">
.dateClass{
    width:100% !important;
    inset:auto !important;
    bottom:0 !important;

   :deep(.el-popper){
     width: 100%;
     bottom: 0;
   }
}
:deep(.el-popper__arrow){
    display: none !important;
  }
:deep(.el-input__wrapper) {
  border: none;
  box-shadow: 0 0 0 1px #ffffff  inset;
}
.priceDateList{
   display: -webkit-box;
   font-size: 1.1rem;
   padding: 12px 16px 0 16px;
   margin-bottom: -7px;
    .yearAndmonth{
       width: 50%;
    }
   .prcieRight{
      width: 50%;
      text-align: right;
   }
}
.priceInfoLeft{
   .font1{
     font-size: 1rem;
     margin-bottom: 0;
   }
  .font2{
     font-size: 0.8rem;
     color: #a9a9a9;
  }
  width: 50%;
}
.priceInfoRight{
  padding-top: 1rem;
  .font1{
    font-size: 1.05rem;
    line-height: 1rem;
  }
  text-align: right;
  flex: 1;
  .el-icon{
    color: #565656;
  }
  .el-icon svg {
    height: 0.97em;
    width: 1em;
    padding-top: 2px;
  }
}
</style>