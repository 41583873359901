<template>
  <div class="contentBg"  v-if="isShow == 4">
    <div class="finance">
      <div
          id="balanceSheetEchartsMonth"
          style="height:15rem;">
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {

  },
  data() {
    return {
      isShow:4,

    };
  },
  methods:{
    switchTaxBillboard(){
      let vm = this;
      vm.$emit('func',vm.isShow);
    },
    //资产负债表
    balanceSheetEcharts() {
      let vm = this;
      if (document.getElementById("balanceSheetEchartsMonth") == null) return;
      this.$echarts.init(document.getElementById("balanceSheetEchartsMonth")).dispose();

      vm.$nextTick(() => {
        let myChart = vm.$echarts.init(
            document.getElementById("balanceSheetEchartsMonth")
        );
        // 组合参数
        const series = [
          {
            type: 'pie',
            radius: ['40%', '70%'],
            center: ['50%', '63%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            labelLine: {
              show: false
            },
            data: [
              { value: 1048, name: '资产总计' },
              { value: 735, name: '负债总计' },
              { value: 580, name: '所有者权益' }
            ]
          }
        ];

        const option = {
          tooltip: {
            trigger: 'item'
          },
          legend: {
            top: '12%',
            left: 'center'
          },
          series: series,
        };
        myChart.setOption(option);
        window.addEventListener("resize", function () {
          myChart.resize();
          // vm.exportChart11 = myChart;
        });
      });
    },
  },
  mounted() {
    this.balanceSheetEcharts();
  },
}
</script>

<style scoped>

</style>