<template>

<!--  概览-->
<div class="contentBg">
  <div class="finance">
    <div
        id="incomeStatementEchartsYear"
        style="height:16rem;">
    </div>
  </div>
</div>

</template>
<script>
export default {
  components: {

  },
  data() {
    return {

    };
  },
  methods:{
    //利润表
    incomeStatementEchartsYear() {
      let vm = this;
       console.log(1);
      if (document.getElementById("incomeStatementEchartsYear") == null) return;
      this.$echarts.init(document.getElementById("incomeStatementEchartsYear")).dispose();

      vm.$nextTick(() => {
        let myChart = vm.$echarts.init(
            document.getElementById("incomeStatementEchartsYear")
        );
        // 组合参数
        const series = [  {
          name: '营业成本',
          type: 'line',
          stack: 'Total',
          data: [120, 132, 101, 134, 90, 230, 210],
          smooth: true
        },
          {
            name: '费用及其他',
            type: 'line',
            stack: 'Total',
            data: [220, 182, 191, 234, 290, 330, 310],
            smooth: true
          }, ];


        const option = {
          tooltip: {
            trigger: 'axis'
          },
          legend: {
            data: ['营业成本', '费用及其他' ],
            top:'6%'
          },
          grid: {
            left: '0',
            right: '2%',
            bottom: '2%',
            containLabel: true
          },

          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月']
          },
          yAxis: {
            type: 'value'
          },
          series: series,
        };
        myChart.setOption(option);
        window.addEventListener("resize", function () {
          myChart.resize();
          // vm.exportChart11 = myChart;
        });
      });
    },
  },
  mounted() {
    this.incomeStatementEchartsYear();
  },
  created() {
  }
}
</script>

<style scoped lang="less">
.finance{
   padding: 1rem 0;
   text-align: center;
   position: relative;
  .financeText{
      position: absolute;
      top: 7.5rem;
      width: 100%;
     p{
      margin: 0;
     }
    .fs1{
       font-size: 0.8rem;
    }
    .fs2{
      font-size: 1.3rem;
    }
  }
}
.incomeStateBtn{
   line-height: 3.4rem;
   margin-top: 15px;
   background: #ffffff;
   display: flex;
   text-align: center;
   font-size: 1.1rem;
   margin-bottom: -15px;
   div{
     flex: 1;
   }
   .hover{
      color: #5398f5;
      border-bottom: 2px #5398f5 solid;
   }
  border-bottom: 2px #f2f2f2 solid;
}
.incomePrice{
   display: flex;
   height: 3.4rem;
   line-height: 3.4rem;
   width: 100%;
   font-size: 1.1rem;
   position: relative;
   .right{
      position: absolute;
      right: 0;
      text-align: right;
     svg{
       color: #cdcdcd;
       padding-top: 4px;
       position: absolute;
     }
   }
   .left{
       padding-left: 2.1rem;
      .el-icon{
         color: #ed751f;
         font-size: 1.3rem;
         position: absolute;
         top: 1.11rem;
         left: 0;
      }
     .drop{
        width: 0.7rem;
        height: 0.7rem;
        border-radius: 0.7rem;
        top: 1.41rem;
        position: absolute;
        left: 0.4rem;
     }
     .d1{
       background: #5470c6;
     }
     .d2{
       background: #91cc75;
     }
     .d3{
       background: #e7c146;
     }

   }
}
.incomePrice::after{
  content: "";
  background-color: #f2f2f2;
  height: 1px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}
.contentBg{
  padding: 0;
}
</style>