<template>
  <div class="priceBg">
      <div id="pricePieChart" style="height:17rem;"></div>
  </div>
  <div class="contentBg">
    <div class="finance">
      <div class="titleDiv">
        <h2>支出趋势<span>（单位：万）</span></h2>
      </div>
      <div
          id="taxBillboardDetail"
          style="height:16rem;">
      </div>
    </div>
  </div>
</template>

<script>

export default {
  components: {

  },
  data() {
    return {
      isShow:0,
      showcom:1,
    };
  },
  methods:{
    switchTaxBillboard(){
       let vm = this;
       vm.$emit('func',vm.isShow);
    },
    //饼图
    pricePieChart(){
      let vm = this;
      if (document.getElementById("pricePieChart") == null) return;
      this.$echarts.init(document.getElementById("pricePieChart")).dispose();

      vm.$nextTick(() => {
        let myChart = vm.$echarts.init(
            document.getElementById("pricePieChart")
        );
        // 组合参数
        const series =   {
          type: 'pie',
          radius: ['60%', '70%'],
          avoidLabelOverlap: true,
          label: {
            show: false,
            position: 'center',

          },
          emphasis: {
            label: {
              formatter: '{b}\n{c}',
              show: true,
              fontSize: 20,
              fontWeight: 'bold',
              fontStyle: 'normal' ,
              lineHeight:30,
              borderColor:0,
              color: '#fff' ,
            },

          },
          labelLine: {
            show: false
          },
          data: [
            { value: 1035, name: '资金余额' },
            { value: 1048, name: '支出金额' },
          ]
        };
        const option = {
          color: ['#ffffff','#02aeff'] ,
          legend: {
            top: '0%',
            left: 'center',
            textStyle: {
              color: '#fff',
            },
          },
          series: series,
        };
        myChart.setOption(option);
        window.addEventListener("resize", function () {
          myChart.resize();
          // vm.exportChart11 = myChart;
        });
      });
    },
    //支出趋势
    drawLine() {
      let vm = this;
      if (document.getElementById("taxBillboardDetail") == null) return;
      this.$echarts.init(document.getElementById("taxBillboardDetail")).dispose();

      vm.$nextTick(() => {
        let myChart = vm.$echarts.init(
            document.getElementById("taxBillboardDetail")
        );
        // 组合参数
        const series = [{
              name: '其他',
              data: [820, 932, 901, 934, 1290, 1330, 1320],
              type: 'line',
              smooth: true
            },
            {
              name: '支出',
              data: [320, 132, 701, 934, 1290, 1030, 1520],
              type: 'line',
              smooth: true
            }
        ];
        const option = {
          xAxis: {
            type: 'category',
            data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月']
          },
          yAxis: {
            type: 'value'
          },
          tooltip: {
            trigger: 'axis'
          },
          legend: {
            data: ['其他', '支出']
          },
          series: series,
        };
        myChart.setOption(option);
        window.addEventListener("resize", function () {
          myChart.resize();
          // vm.exportChart11 = myChart;
        });
      });
    },
  },
  mounted() {
    this.drawLine();
    this.pricePieChart();
  },
}
</script>

<style scoped lang="less">
.priceBg{
  background: linear-gradient(to right, #12d1ff, #02afff);
  margin-top: -1px;
  padding-top: 2rem;
}
.contentBg{
  .titleDiv{
     h2{
       span{
         color: #999999;
         font-size: 0.6rem;
       }
     }
  }
}
</style>