<template>
  <div class="contentBg" v-if="isShow == 0">
    <div class="finance">
            <div
                id="taxBillboardYear"
                style="height:16rem;padding:1rem 0">
            </div>
       </div>
  </div>

</template>

<script>

export default {
  components: {

  },
  data() {
    return {
      isShow:0,
      showcom:1,
      isSwitch:2,
    };
  },
  methods:{
    switchTaxBillboard(){
      let vm = this;
       vm.$emit('func',vm.isShow);
       vm.isShow=1;
    },
    switchCharts(type){
      let vm = this;
       vm.isSwitch = type;
    },
    //财税看板
    drawLine() {
      let vm = this;
      if (document.getElementById("taxBillboardYear") == null) return;
      this.$echarts.init(document.getElementById("taxBillboardYear")).dispose();

      vm.$nextTick(() => {
        let myChart = vm.$echarts.init(
            document.getElementById("taxBillboardYear")
        );
        // 组合参数
        const series = [
          {
            name: '资金余额',
            type: 'line',
            stack: 'Total',
            data: [120, 132, 101, 134, 90, 230, 210],
            smooth: true
          },
          {
            name: '支出余额',
            type: 'line',
            stack: 'Total',
            data: [220, 182, 191, 234, 290, 330, 310],
            smooth: true
          },
        ];
        const option = {
          tooltip: {
            trigger: 'axis'
          },
          legend: {
            data: ['资金余额', '支出余额' ],
            top:'3.5%'
          },
          grid: {
            left: '0',
            right: '2%',
            bottom: '2%',
            containLabel: true
          },

          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月']
          },
          yAxis: {
            type: 'value'
          },
          series: series,
        };
        myChart.setOption(option);
        window.addEventListener("resize", function () {
          myChart.resize();
          // vm.exportChart11 = myChart;
        });
      });
    },
  },
  mounted() {
    this.drawLine();
  },
}
</script>

<style scoped lang="less">
.finance{
  position: relative;

  .financeText{
    position: absolute;
    top: 6.8rem;
    width: 100%;
    text-align: center;
    p{
      margin: 0;
    }
    .fs1{
      font-size: 0.8rem;
    }
    .fs2{
      font-size: 1.3rem;
    }
  }
}
.yearBtn{
   color: #02afff;
   width: 5rem;
   height:2.4rem;
   line-height: 2.4rem;
   text-align: center;
   border: #02afff solid 1px;
  border-radius: 0.6rem;
  position: absolute;
  background: none;
  left: 0;
  font-weight: bold;
  z-index: 9999;
}
.contentBg{
  padding: 0;
}
</style>