<template>
<!-- 明细  -->
<div class="contentBg">
  <div class="finance">
    <div
        id="propertyDetailsEcharts1"
        style="height:36rem;"
    >
    </div>
  </div>
</div>

</template>
<script>

export default {
  components: {

  },
  data() {
    return {

    };
  },
  methods:{
//资产负债表明细旭日图
    propertyDetailsEcharts1() {
      let vm = this;
      if (document.getElementById("propertyDetailsEcharts1") == null) return;
      this.$echarts.init(document.getElementById("propertyDetailsEcharts1")).dispose();

      vm.$nextTick(() => {
        let myChart = vm.$echarts.init(
            document.getElementById("propertyDetailsEcharts1")
        );

        // 组合参数
        const data = [
          {
            name: '流动资产',
            children: [
              {
                name: '货币资金',
                value: 11,
                children: [
                  {
                    name: '银行存款',
                    value: 2
                  },
                  {
                    name: '库存现金',
                    value: 5,
                  },
                  {
                    name: '其他货币资金',
                    value: 4
                  }
                ]
              },
              {
                name: '交易性金融资产',
                value: 9,
                children: [
                  {
                    name: '成本',
                    value: 5
                  },
                  {
                    name: '公允价值变动',
                    value: 4
                  }
                ]
              }
            ]
          },
          {
            name: '非流动资产',
            children: [
              {
                name: '长期应收款',
              },
              {
                name: '长期股权投资',
                children: [
                  {
                    name: '投资成本',
                    value: 5
                  },
                  {
                    name: '其他综合收益',
                    value: 4
                  },
                  {
                    name: '损益调整',
                    value: 8
                  }
                ]
              },
              {
                name: '固定资产',
                children: [
                  {
                    name: '加项',
                    value: 8,
                    children: [
                      {
                        name: '增值税发票',
                        value: 5
                      },
                      {
                        name: '资产卡片增加',
                        value: 3
                      },
                    ]
                  },
                  {
                    name: '减项',
                    value: 9,
                    children: [
                      {
                        name: '盘亏',
                        value: 3
                      },
                      {
                        name: '报废',
                        value: 3
                      },
                      {
                        name: '毁损',
                        value: 3
                      },
                    ]
                  },
                ]
              },
              {
                name: '在建工程',
                children: [
                  {
                    name: '加项',
                    value: 5,
                    children: [
                      {
                        name: '到货单',
                        value: 5
                      },
                    ]
                  },
                  {
                    name: '减项',
                    value: 5,
                    children: [
                      {
                        name: '完工验收单',
                        value: 5
                      },
                    ]
                  },
                ]
              },
            ]
          },
          {
            name: '应付票据',
            children: [
              {
                name: '加项',
                value: 7,
                children: [
                  {
                    name: '收到专用发票',
                    value: 2
                  },
                  {
                    name: '材料入库单',
                    value: 5,
                  }
                ]
              },
              {
                name: '减项',
                value: 5,
                children: [
                  {
                    name: '付款单',
                    value: 5
                  }
                ]
              }
            ]
          },
          {
            name: '短期借款',
            children: [
              {
                name: '加项',
                value: 12,
                children: [
                  {
                    name: '收款凭证',
                    value: 7
                  },
                  {
                    name: '借款合同',
                    value: 5,
                  }
                ]
              },
              {
                name: '减项',
                value: 5,
                children: [
                  {
                    name: '还款回单',
                    value: 5
                  }
                ]
              }
            ]
          },
          {
            name: '预收账款',
            children: [
              {
                name: '加项',
                value: 7,
                children: [
                  {
                    name: '银行收款单',
                    value: 7
                  },
                ]
              },
              {
                name: '减项',
                value: 10,
                children: [
                  {
                    name: '销售发票',
                    value: 5
                  },
                  {
                    name: '销售单',
                    value: 5
                  }
                ]
              }
            ]
          },
          {
            name: '应交税费',
            children: [
              {
                name: '加项',
                value: 7,
                children: [
                  {
                    name: '计税基础*税率',
                    value: 7
                  },
                ]
              },
              {
                name: '减项',
                value: 5,
                children: [
                  {
                    name: '计税基础*税率',
                    value: 5
                  },
                ]
              }
            ]
          },
          {
            name: '其他应付款',
            children: [
              {
                name: '加项',
                value: 7,
                children: [
                  {
                    name: '其他应付款',
                    value: 7
                  },
                ]
              },
              {
                name: '减项',
                value: 5,
                children: [
                  {
                    name: '付款单',
                    value: 5
                  },
                ]
              }
            ]
          },
          {
            name: '所有者权益',
            children: [
              {
                name: '实收资本',
                value: 14,
                children: [
                  {
                    name: '股权协议',
                    value: 7
                  },
                  {
                    name: '章程',
                    value: 7
                  },
                ]
              },
              {
                name: '资本公积',
                value: 5,
                children: [
                  {
                    name: '股权协议',
                    value: 5
                  },
                ]
              },
              {
                name: '盈余公积',
                value: 10,
                children: [
                  {
                    name: '法定盈余公积',
                    value: 5
                  },
                  {
                    name: '任意盈余公积',
                    value: 5
                  },
                ]
              },
              {
                name: '未分配利润',
                value: 5,
                children: [
                  {
                    name: '利润表数据',
                    value: 5
                  },
                ]
              }
            ]
          },
        ];
        const option = {
          series: {
            type: 'sunburst',
            // emphasis: {
            //     focus: 'ancestor'
            // },
            data: data,
            radius: ['0', '100%'],
            center:['47%','40%'],
            label: {
              rotate: 'radial'
            }
          }
        };
        myChart.setOption(option);
        window.addEventListener("resize", function () {
          myChart.resize();
          // vm.exportChart11 = myChart;
        });
      });
    },

  },
  mounted() {
    this.propertyDetailsEcharts1();
  },
  created() {
  }
}
</script>

<style scoped lang="less">
.contentBg{
  padding: 0;
}
.finance{
   padding: 1rem 0;
   text-align: center;
   position: relative;
  .financeText{
      position: absolute;
      top: 7.5rem;
      width: 100%;
     p{
      margin: 0;
     }
    .fs1{
       font-size: 0.8rem;
    }
    .fs2{
      font-size: 1.3rem;
    }
  }
}
.incomeStateBtn{
   line-height: 3.4rem;
   margin-top: 15px;
   background: #ffffff;
   display: flex;
   text-align: center;
   font-size: 1.1rem;
   margin-bottom: -15px;
   div{
     flex: 1;
   }
   .hover{
      color: #5398f5;
      border-bottom: 2px #5398f5 solid;
   }
  border-bottom: 2px #f2f2f2 solid;
}
.incomePrice{
   display: flex;
   height: 3.4rem;
   line-height: 3.4rem;
   width: 100%;
   font-size: 1.1rem;
   position: relative;
   .right{
      position: absolute;
      right: 0;
      text-align: right;
     svg{
       color: #cdcdcd;
       padding-top: 4px;
       position: absolute;
     }
   }
   .left{
       padding-left: 2.1rem;
      .el-icon{
         color: #ed751f;
         font-size: 1.3rem;
         position: absolute;
         top: 1.11rem;
         left: 0;
      }
     .drop{
        width: 0.7rem;
        height: 0.7rem;
        border-radius: 0.7rem;
        top: 1.41rem;
        position: absolute;
        left: 0.4rem;
     }
     .d1{
       background: #5470c6;
     }
     .d2{
       background: #91cc75;
     }
     .d3{
       background: #e7c146;
     }

   }
}
.incomePrice::after{
  content: "";
  background-color: #f2f2f2;
  height: 1px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}
.yearBtn{
  color: #02afff;
  width: 5rem;
  height:2.4rem;
  line-height: 2.4rem;
  text-align: center;
  border: #02afff solid 1px;
  border-radius: 0.6rem;
  position: absolute;
  background: none;
  left: 0;
  font-weight: bold;
  z-index: 9999;
}
</style>