<template>
  <div class="contentBg" v-if="isShow == 0">
    <div class="finance">
      <div class="titleDiv" >
        <h2>财税看板
          <div @click="switchTaxBillboard()">
            <el-icon><ArrowRight /></el-icon>
          </div>
        </h2>
        <button v-if="isSwitch == 2" class="yearBtn" @click="switchCharts(3)">当月</button>
        <button v-if="isSwitch == 3" class="yearBtn" @click="switchCharts(2)">当年</button>
      </div>
      <taxBillboardYear v-if="isSwitch==2"></taxBillboardYear>
      <taxBillboardMonth v-if="isSwitch==3"></taxBillboardMonth>
    </div>
  </div>

</template>

<script>
import TaxBillboardYear from "@/components/financialManagement/taxBillboardYear";
import taxBillboardMonth from "@/components/financialManagement/taxBillboardMonth";
export default {
  components: {
    TaxBillboardYear,
    taxBillboardMonth
  },
  data() {
    return {
      isShow:0,
      showcom:1,
      isSwitch:2,
    };
  },
  methods:{
    switchTaxBillboard(){
      let vm = this;
       vm.$emit('func',vm.isShow);
       vm.isShow=1;
    },
    switchCharts(type){
      let vm = this;
       vm.isSwitch = type;
    },
    //财税看板
    drawLine() {
      let vm = this;
      if (document.getElementById("taxBillboard") == null) return;
      this.$echarts.init(document.getElementById("taxBillboard")).dispose();

      vm.$nextTick(() => {
        let myChart = vm.$echarts.init(
            document.getElementById("taxBillboard")
        );

        // 组合参数
        const series = [];
        const obj = {
          name: '财税看板',
          type: 'pie',
          radius: ['50%', '60%'],
          avoidLabelOverlap: true,
          center: ['50%', '46%'],
          label: {
            formatter: '{c}%',
            minMargin: 5,
            lineHeight: 18,
            rich: {
              time: {
                fontSize: 12,
                color: '#7b7b7b'
              }
            }
          },

          data: [
            {value: 10.00, name: '银行存款'},
            {value:20.00, name: '库存现金'},
            {value: 70.00, name: '支出工资'}
          ]
        };
        series.push(obj);
        const option = {
          tooltip: {
            trigger: 'item',
          },
          legend: {
            left: 'center',
            bottom:'8%'
          },
          series: series,
        };
        myChart.setOption(option);
        window.addEventListener("resize", function () {
          myChart.resize();
          // vm.exportChart11 = myChart;
        });
      });
    },
  },
  mounted() {
    this.drawLine();
  },
}
</script>

<style scoped lang="less">
.finance{
  position: relative;

  .financeText{
    position: absolute;
    top: 6.8rem;
    width: 100%;
    text-align: center;
    p{
      margin: 0;
    }
    .fs1{
      font-size: 0.8rem;
    }
    .fs2{
      font-size: 1.3rem;
    }
  }
}
.yearBtn{
   color: #02afff;
   width: 5rem;
   height:2.4rem;
   line-height: 2.4rem;
   text-align: center;
   border: #02afff solid 1px;
  border-radius: 0.6rem;
  position: absolute;
  background: none;
  left: 0;
  font-weight: bold;
  z-index: 9999;
}
</style>